<template>
  <div class="newgame">
    <!-- <navbar></navbar> -->
    <!-- <div class="portrait-wrap"> -->
    <transition-group name="fade">
      <!-- Show to preload the other image -->
      <img key="1" v-show="character === 'ironclad'" src="./../assets/backgrounds/ironcladPortrait.jpg" class="portrait" alt="">
      <img key="2" v-show="character === 'silent'" src="./../assets/backgrounds/silentPortrait.jpg" class="portrait" alt="">
      <img key="3" v-show="character === 'defect'" src="./../assets/backgrounds/defectPortrait.jpg" class="portrait" alt="">
      <img key="4" v-show="character === 'watcher'" src="./../assets/backgrounds/watcherPortrait.jpg" class="portrait" alt="">
    </transition-group>
    <!-- </div> -->
    <div class="select-character">
      <router-link class="return" :to="{ name: 'sls-home'}">
        <img src="./../assets/ui/returnArrow2.png" alt="">
        <p>Return to Homepage</p>
      </router-link>

      <div class="select-character-buttons">
        <div @click="character = 'ironclad'">
          <img src="./../assets/buttons/ironclad.png" alt="">
        </div>
        <div @click="character = 'silent'">
          <img src="./../assets/buttons/silent.png" alt="">
        </div>
        <div @click="character = 'defect'">
          <img src="./../assets/buttons/defect.png" alt="">
        </div>
        <div @click="character = 'watcher'">
          <img src="./../assets/buttons/watcher.png" alt="">
        </div>
      </div>
      <div class="button-wrap">
        <button class="button" @click="confirmCharacter">
          <div>
            <p>Start</p>
          </div>
        </button>
      </div>
      <!-- <button class="button" @click="confirmCharacter()">start</button> -->
    </div>

  </div>
</template>

<script>
import navbar from './nav';
import ironcladPortrait from './../assets/backgrounds/ironcladPortrait.jpg';
import silentPortrait from './../assets/backgrounds/silentPortrait.jpg';

export default {
  name: 'newgame',
  components: { navbar },
  data() {
    return {
      character: '',
    };
  },
  methods: {
    confirmCharacter() {
      console.log('-- Welcome! --');
      console.log('New deck created. Add cards to your deck as you play and then compare cards you are offered in the compare tab.');

      if (this.character === '' || this.character === null) {
        // console.log('failed');

        return;
      }
      // console.log(this.character);
      this.$ga.event({
        eventCategory: 'Slay The Spire',
        eventAction: 'Character Loadout',
        eventLabel: this.character,
        eventValue: 1,
      });
      this.$emit('characterData', this.character);
      this.$router.push({ name: 'sls-loadout' });
    },
  },
  mounted() {
    const vue = this;
    setTimeout( () => {
      vue.character = 'ironclad';
    }, 80);
  },
};
</script>

<style lang="scss" scoped>
.newgame {
  height: 100vh;
  overflow: hidden;
  padding: 1px 0;
  // prevent overlap of <p> leaving white gap at bottom of page
  transition: background-image 0.25s ease-in-out;
}

a {
  color: #fff;
  text-decoration: none;
  font-family: 'Kreon', serif;
  // font-weight: 300;
}

.portrait {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.02);
}

.return-button {
  font-size: 20px;
}

.return {
  position: absolute;
  top: 9px;
  left: 10px;

  color: #fff6e1;
  text-decoration: none;
  font-size: 20px;
  padding: 20px 22px;
  display: flex;
  align-items: center;

  > img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 16px;
  }
  p {
    padding: 0;
    margin: 0;
  }
}

.select-character {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  position: relative;
  padding-bottom: 50px;
  &-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
    width: 400px;
    > div {
      > img {
        width: 140px;
      }
    }
  }
}

.button {
  display: flex;
  // width: 96%;
  // height: 50px;
  font-family: 'Kreon', serif;

  justify-content: center;
  background: none;
  border: none;
  margin: auto;
  outline: none;
  color: #fff6e1;
  > div {
    // background: #000;
    width: 150px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    // background: url('./../assets/ui/buttonL2.png');
    background: url('./../assets/ui/buttonHover2.png');

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);

    display: flex;
    &:hover {
      transform: scale(1.08,1.08);
      transition: 0s;
      z-index: 100;
      background: url('./../assets/ui/buttonHover2.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    > p {
      padding: 0;
      margin: 0 0 2px;
      font-size: 20px;
    }
  }
  // .inactive {
  //   filter: grayscale(100%);
  //   transform: scale(0.95,0.95);
  //   color: rgb(187, 187, 187);

  //   &:hover {
  //     transform: scale(0.95,0.95);
  //     // background: url('./../assets/ui/buttonL2.png');
  //     background: url('./../assets/ui/buttonHover2.png');
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     background-position: center;
  //   }
  // }
}

// Animation

.fade-enter-active,
.fade-leave-active {
  transition: all 190ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  // transition: bounce 200ms ;
  transform: translateY(0px) scale(1.02);
    z-index: 0;

}
.fade-enter {
  opacity: 0;
  transform: translateY(-100px) scale(1.02);
  // z-index: 10;
}
.fade-leave-to {
  transform: translateY(0px) scale(1.02);
  opacity: 1;
  z-index: -1;

}
</style>
