<template>
  <div class="comparator">
    <div class="wrapper">
      <!-- <button class="button button-close" @click="$emit('close')">
        <p>Close</p>
      </button> -->
      <button class="button button-reset" @click="$emit('resetCompare')">
        <p>Reset</p>
      </button>
      <div class="info">
        <h1>Compare Cards & Relics</h1>
        <p>Find information about the best cards and relics to add to your loadout. Find information about synergies and antisynergies with your current cards and relics (loadout) based on our algorithm.</p>
      </div>
      <ul class="cards-list">
        <li v-for="(item, index) in compare" class="card-single" :key="index" :id="`card-${index}`">
          <card v-if="item.index < cardsLength"
            :character="item.character"
            :title="item.name"
            :type="item.type"
            :mana="item.mana"
            :manaplus="item.manaplus"
            :rarity="item.rarity"
            :tier="item.tier"
            :description="item.description"
            :descriptionplus="item.descriptionplus"
            :plus="item.plus"
            :beta="item.beta"
            :comparator="true"
            :browser="false"
            :mydeckIndex="index"
            @addCardInfo="addCardToDeck"
            @upgradeCard="upgradeComparisonCard"
            @downgradeCard="downgradeComparisonCard"
            @removeCard="removeComparisonItem"
          ></card>
          <relic v-else
            :character="item.character"
            :title="item.name"
            :rarity="item.rarity"
            :description="item.description"
            :mydeckIndex="index"
            @addRelicInfo="addRelicToDeck"
            @removeRelic="removeComparisonItem"
            :comparator="true"
          ></relic>
          <div class="card-comparison-info">
            <h2 class="title">synergies</h2>
            <ul class="list-block synergies-block-height" > <!-- :style="{height: blockHeightSynergies + 'px'}" -->
              <!-- <li v-if="itemSynergies(item.synergies).length === 0 && item.statementssynergies.length === 0">
                <p>No known synergies</p>
              </li> -->
              <!-- <li v-for="statement in item.statementssynergies" class="statement">
                <p>{{statement}}</p>
              </li> -->
              <!-- <li v-for="(cardsyn, indexsynergy) in itemSynergies(item.synergies)" class="synergy">
                <p>{{cardsyn.name}}</p>
                 <p>{{cardsyn.type}}</p> 
              </li> -->
              <li v-for="(ratio, indexsynergy) in itemRatioSynergies(item.ratios, 'synergy')" class="synergy" :v-if="ratio !== 0">
                <p>{{ratio.statement}}</p>
              </li>
              <!-- New block using all data -->
              <li v-for="(synergy, indexSynergy) in synergyList(item)" :class="synergy.style">
                <p>{{synergy.text}} <template v-if="synergy.number > 1">x {{synergy.number}}</template> <template v-if="synergy.type">[{{synergy.type}}]</template></p>
              </li>
            </ul>
            <h2 class="title">antisynergies</h2>
              <ul class="list-block antisynergies-block-height" > <!-- :style="{height: blockHeightAntisynergies + 'px'}" -->
              <!-- <li v-if="itemSynergies(item.antisynergies).length === 0 && item.statementsantisynergies.length === 0" class="none">
                <p class="none">No known antisynergies</p>
              </li>
              <li v-for="statement in item.statementsantisynergies" class="statement">
                <p>{{statement}}</p>
              </li>
              <li v-for="(cardantisyn, indexsynergy) in itemSynergies(item.antisynergies)" class="antisynergy">
                <p>{{cardantisyn.name}}</p>
              </li> -->
              <li v-for="(ratio, indexsynergy) in itemRatioSynergies(item.ratios, 'antisynergy')" class="antisynergy" :v-if="ratio !== 0">
                <p>{{ratio.statement}}</p>
              </li>

              <li v-for="(antisynergy, indexsynergy) in antisynergyList(item)" :class="antisynergy.style">
                <p>{{antisynergy.text}} <template v-if="antisynergy.number > 1">x {{antisynergy.number}}</template> <template v-if="antisynergy.type">[{{antisynergy.type}}]</template></p>
              </li>
              <!-- <li v-if="itemSynergies(card.antisynergies).length > 0">test</li> -->
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


import card from './cardv2';
import relic from './relic';
import allData from './carddata.json';

export default {
  name: 'sls-comparator',
  components: { card, relic },
  props: {
    compare: {
      type: Array,
      required: true,
    },
    mycards: {
      type: Array,
      required: true,
    },
    myRelics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      cardsLength: allData.cards.length
    };
  },
  methods: {
    addCardToDeck(name, plus) {
      this.$emit('addCardInfo', name, plus);
      this.cardAddedToDeckFromComparison(name); // adding to db stats
    },
    removeComparisonItem(itemID) {
      this.$emit('removeComparisonItem', itemID);
    },
    upgradeComparisonCard(cardID) {
      this.$emit('upgradeComparisonCard', cardID);
    },
    downgradeComparisonCard(cardID) {
      this.$emit('downgradeComparisonCard', cardID);
      // this.mycards[cardID].plus = false;
    },
    addRelicToDeck(name) {
      this.$emit('addRelicInfo', name);
    },
    itemSynergies(itemSynergiesData) {
      // let returnItem = [];
      let cards = this.mycards.filter(function(deckcard) {
        // console.group(deckcard.name);
        for (let i = 0; i < itemSynergiesData.length; i++) {
          const cardSynergy = itemSynergiesData[i];
          // console.log('Synergy ID: '+ cardSynergy);

          // console.log('Deck Index: '+ deckcard.index);

          if (itemSynergiesData[i] === deckcard.index) {
            // console.log('matched ' + deckcard.name + ' ' + cardSynergy);
            // console.log(deckcard);
            // console.groupEnd(deckcard.name);
            // returnItem.push(deckcard)
            return deckcard;
          }
        }
      });
      // console.log(cards);
      let relics = this.myRelics.filter((deckcard) => {
        // console.group(deckcard.name);

        for (let i = 0; i < itemSynergiesData.length; i++) {
          const cardSynergy = itemSynergiesData[i];
          // console.log('Synergy ID: '+ cardSynergy);

          // console.log('Deck Index: '+ deckcard.index);

          if (itemSynergiesData[i] === deckcard.index) {
            // console.log('matched ' + deckcard.name + ' ' + cardSynergy);
            // console.log(deckcard);
            // console.groupEnd(deckcard.name);
            // returnItem.push(deckcard)
            return deckcard;
          }
        }
      });
      // console.log(relics);
      const all = cards.concat(relics);
      // console.log('relic synergy');
      // console.log(all);
      return all;
    },
    itemRatioSynergies(ratioStatements, synOrAnti){
      const res = ratioStatements.map((ratioSynergy) => {
        // Get ratio info
        const synergyStatementArray = ratioSynergy.split(':');
        let key = synergyStatementArray[0];
        let value = synergyStatementArray[1];
        const targetFraction = synergyStatementArray[2];
        const greaterLessThan = synergyStatementArray[3];

        // Key corrections
        if (key === 'tag') {
          key = 'tags';
        }

        // filter mycards
        let result;
        if (key === 'tags') {
          result = this.mycards.filter((card) => {
            // console.log(card.tags.length);

            var list = card.tags.filter((filter) => filter[0] === value);
            // console.log(list);
            if (list.length > 0) {
              return card;
            }
            return;
          });
        } else if (key === 'mana') {
          // console.log(value);

          const symbol = value.charAt(0);
          // console.log(symbol);
          const value2 = parseInt(value.substring(1));
          // console.log(value2);

          if (symbol === '=') {
            result = this.mycards.filter((card) => card.mana === value2);
          }
          if (symbol === '>') {
            result = this.mycards.filter((card) => card.mana > value2);
          }
          if (symbol === '<') {
            result = this.mycards.filter((card) => card.mana < value2);
          }

        } else {
          result = this.mycards.filter((card) => card[key] === value);
        }

        // console.log(`-- cards matching ratio '${key}:${value}' --`);
        // console.log(result);
        // console.log(`${result.length} ${value} cards`);

        // calc
        const totalNumberOfCards = this.mycards.length;
        const numberOfCardsMatchingCriteria = result.length;
        const calcFraction = numberOfCardsMatchingCriteria / totalNumberOfCards;
        // console.log(calcFraction);

        // return values
        const percentage = this.precisionRound((calcFraction * 100), 0);
        const returnStatement = {
          'statement': `${percentage}% ${value} cards`,
          'synergy': ''
        }
        // mana statement fix
        if (key === 'mana') {
          const symbol = value.charAt(0);
          const value2 = value.substring(1);
          returnStatement.statement = `${percentage}% mana ${symbol}${value2} cards`;
        }
        // X% 'attack' cards

        // console.log(`Is ${calcFraction} ${greaterLessThan} ${targetFraction}?`);
        if (greaterLessThan === '>') {
          if (calcFraction > targetFraction) {
            returnStatement.synergy = 'synergy';
            return returnStatement;
          }
          returnStatement.synergy = 'antisynergy';
          return returnStatement;

        } else if (greaterLessThan === '<') {
          if (calcFraction < targetFraction) {
            returnStatement.synergy = 'synergy';
            return returnStatement;
          }
          returnStatement.synergy = 'antisynergy';
          return returnStatement;

        } else {
          // return
        }
      });

      if (res[0] !== undefined && res[0].synergy === synOrAnti) {
        // console.log(res);
        return res;
      }
      return 0;
      // return
      // console.log(test);
      // calc
    },

    // New Data List

    // Simple gateway for synergies and antisynergies
    synergyList(data) {
      return this.itemComparisons(data, 'synergies');
    },
    antisynergyList(data) {
      return this.itemComparisons(data, 'antisynergies');
    },
    itemComparisons(itemData, type) {
      const synergyArray = itemData[type];
      // const statements = itemData[`statements${type}`];
      // const statements = itemData.statement;
      const ratios = itemData.ratios;

      const list = [];
      // console.log(itemData.name);

      // Data object returned as array
      // {
      //   'text': 'The text displayed in the li',
      //   'style': 'Statement, Card, Relic, ',
      //   'type': 'Relic / Card shown as [Card] (after name)'
      //   'number': 'Number of cards/relics of the same name'
      //   'reason': 'Reason for synergy as a statement (coming soon)'
      // }

      // Statements
      // statements.forEach(statement => list.push({ 'text': statement, 'style':'statement'}));

      // Cards
      let cards = this.listItems(this.mycards, synergyArray);
      cards.sort(this.sortArray);
      cards = this.sumOfItemUniques(cards);
      cards = this.finalListObject(cards, 'Card',type);
      cards.forEach(card => list.push(card));

      // Relics
      let relics = this.listItems(this.myRelics, synergyArray);
      relics.sort(this.sortArray);
      relics = this.sumOfItemUniques(relics);
      relics = this.finalListObject(relics, 'Relic', type);
      relics.forEach(relic => list.push(relic));

        // Ratios
        // What cards might be useful as you dont have any

      if (list.length === 0) {
        list.push({'text': `No known ${type} with current loadout`, 'style':'none'});
      }
      return list;
    },
    listItems(type, synergyArray) {
      return type.filter(function(deckcard) {
        for (let i = 0; i < synergyArray.length; i++) {
          const cardSynergy = synergyArray[i];
          if (cardSynergy === deckcard.index) {
            return deckcard.name;
          }
        }
      });
    },
    sortArray(a,b) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    },
    sumOfItemUniques(array) {
      var counts = {};
      array.forEach((single) => { counts[single.name] = (counts[single.name] || 0)+1;});
      return counts;
      console.log(counts)
    },
    finalListObject(array, type, style) {
      return Object.entries(array).map(([key, value]) => (
         {
            'text': key,
            'number': value,
            'type': type,
            'style': style,
          }
        ));
    },
    listStatements(data) {
      data
    },
    blockHeight(selector) {
      // console.log(selector);

      const elements = document.querySelectorAll(selector);
      // return this.getMinHeight (elements);
      let min = 0
      elements.forEach((element) => {
        element.style.removeProperty('height')
      });
      elements.forEach((element) => {
        if (element.offsetHeight > min) {
          min = element.offsetHeight
        }
      });
      // console.log(min);
      return min;
    },
    getMinHeight(elements) {

    },
    // Ratio Comparison
    ratios() {

    },
    precisionRound(number, precision) {
      var factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    },

    // server calls
    async cardAddedToDeckFromComparison(name) {
      try {
        const res = await this.$api.post(
          '/', {
            query: `
              mutation ($name: String!){
                cardAddedToDeckFromComparison(name: $name) {
                  success
                }
              }
            `,
            variables: {
              name: name
            }
        })
        console.log(res.data.data);

      } catch (e) {
        console.log('err', e)
      }
    },
  // methods end //
  },
  computed: {
    // itemSynergies(itemSynergiesData, indexsynergy) {
    //   return this.mycards.forEach(function(deckcard) {
    //     // console.log(card);
    //     console.log(card);

    //     for (let i = 0; i < itemSynergiesData.length; i++) {
    //       if (itemSynergiesData[i] === deckcard.id) {
    //         console.log(deckcard.id);

    //         return deckcard;
    //       }
    //     }
    //   });
    // },

    blockHeightSynergies() {
      this.comparecards;
      this.mycards;
      const height = this.blockHeight('.synergies-block-height');
      return height;
    },
    blockHeightAntisynergies() {
      this.comparecards;
      this.mycards;
      const height = this.blockHeight('.synergies-block-height');
      return height;
    }
  }
}
</script>

<style lang="scss" scoped>
.comparator {
  padding: 60px 0 200px;
  background: rgba(#10212B, 0.85);
  box-shadow: rgba(rgb(201, 191, 146), 1) 0 -6px 0 0;
  border-top: 5px solid #364f57;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}

.cards-list {
  display: flex;
  list-style: none;
  justify-content: center;
  > .card-single {
    // flex-direction: column;
    // width: 25%;
    max-width: 260px;
    margin: 0 26px;
    // > .card-single {

    // }
  }
}

// .cards {
//   max-width: 1280px;
//   margin: 0 auto;

//   > ul {
//   list-style: none;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 10px 40px;
//   margin: 0 auto;
//   }

//   > li {
//     width: 19%;
//     // width: 45%;
//     margin: 1% 0.5%;
//     display: flex;
//     align-items: flex-end;
//     // overflow: hidden;
//     .card {
//       transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
//       // transition: scale 0.5s easing's easeOutCubic curve;
//       &:hover {
//         // transform: scale(1.28, 1.28);
//         transition: 0s;
//         z-index: 100;
//         & ~ #sts-card-base-img {
//           box-shadow: 0 0 20px 20px rgba(#000, 0.4);
//         }
//         // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
//       }
//     }
    
//   }
// }


.info{
  max-width: 780px;
  text-align: left;
  padding: 0 20px;
  margin-bottom: 50px;
  p {
    line-height: 140%;
  }

}

.button {
  width: 220px;
  height: 100px;
  z-index: 4000;
  background: none;
  outline: none;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  transform: scale(0.9);
  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background: url('./../assets/ui/buttonL2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    transform: scale(1);
    transition: 0s;
    background: url('./../assets/ui/buttonHover2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  > p {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
  }

  &-reset {
    position: fixed;
    top: 40px;
    right: 50px;
  }
  // &-reset {
  //   position: fixed;
  //   top: 40px;
  //   right: 260px;
  // }

}

.card-comparison-info {
  width: 90%; 
  margin: 0 auto;
  padding: 1px 0;
}

.list-block {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 0 1px 0;
  list-style: none;
  width: 100%;
  > li {
    border-bottom: 1px solid rgba(#000, 0.4);
    width: 100%;
    &:hover {
      cursor: pointer;
      border-bottom: 0px solid rgba(#000, 0.4);

    }
    > p {
      margin: 0;
      padding: 6px 9px;
    }
  }

  .synergy, .synergies { // new is synergies (delete other after update of lists)
    background: #0d690d;
    &:hover {
      background: lighten(#0d690d, 7%);
    }

  }
  .antisynergy, .antisynergies { // new is antisynergies (delete other after update of lists)
    background: #5e0c0c;
    &:hover {
      background: lighten(#5e0c0c, 7%);
    }

  }
  .statement {
    background: #4e4e4e;
    &:hover {
      background: lighten(#4e4e4e, 7%);
      cursor: default;

    }
  }
  .none {
    background: rgba(#000, 0.75);
    &:hover {
      background: lighten(#000000, 15%);
      cursor: default;;
    }
  }

}

.title {
  text-transform: capitalize;
  text-align: center;
  margin-top: 30px;
}
</style>
