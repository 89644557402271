<template>
  <div class="char-select"> <!-- :class="{ 'scroll-lock': (showBrowse || showCompare) } -->
    <characterSelect
      v-if="character === '' || character === null"
      @characterData="confirmCharacter"
    >
    </characterSelect>
    <div class="loadout" v-else>
      <!-- attempt to add linear gradient image -->
      <!-- <div class="main-bg" :style="styleMainBgImageGradient"> -->
        <!-- <img class="img" src="./../assets/backgrounds/ironcladPortrait.jpg" alt=""> -->
      <!-- </div> -->

      <!-- <navbar></navbar> -->
        <!-- <div > -->
          <!-- <transition-group
            tag="div"
            class="nav-alt"
            name="fade1"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
          > -->
          <div class="nav-alt">
            <router-link class="return" :to="{ name: 'sls-home'}" :key="1" :data-index="1">
              <img src="./../assets/ui/returnArrow2.png" alt="">
              <div class="text">
                <p class="mini">Your loadout is autosaved</p>
                <p>Return to Homepage</p>
              </div>
            </router-link>
            <div v-if="mycards.length > 0" class="issue" @click="feedbackTemp" :key="2" :data-index="2">
              <p class="main">{{feedbackMain}}</p>
              <p class="mini">{{feedbackSmall}}</p>
            </div>

          </div>

          <!-- </transition-group> -->
          <p class="temp-loading" v-if="tempLoad">loading<p>
        <!-- </div> -->
      <div class="cards">
        <transition-group
          name="slide1"
          v-bind:css="false"
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          v-on:leave="leave"
        >
          <h1 v-if="mycards.length > 0" :key="1" :data-index="3">{{this.character}} Deck</h1>
          <h2 v-if="mycards.length > 0" :key="2" :data-index="4">{{mycards.length}} Cards</h2>
          <!-- <button @click="buildDeck('resume')" :key="4" :data-index="4"></button> -->
          <!-- <ul> -->
          <transition-group
            name="slide1"
            tag="ul"
            v-bind:css="false"
            v-on:before-enter="beforeEnterCard"
            v-on:enter="enterCard"
            v-on:after-enter="afterEnter"
            v-on:leave="leave"

            :key="3"
            :data-index="5"
            v-if="mycards.length > 0"

          >
            <li v-for="(card, index) in mycards"
             :data-index="6"
             :key="card.keyNumberIndex"
             :id="`card-${index}`"
             :data-itemindex="index"
            >

              <card
                :character="card.character"
                :title="card.name"
                :type="card.type"
                :mana="card.mana"
                :manaplus="card.manaplus"
                :rarity="card.rarity"
                :tier="card.tier"
                :description="card.description"
                :descriptionplus="card.descriptionplus"
                :plus="card.plus"
                :beta="card.beta"
                :browser="false"
                :mydeckIndex="index"
                :mydeck="mycards"
                @upgradeCard="upgradeCard"
                @downgradeCard="downgradeCard"
                @removeCard="removeCard"
              ></card>
            </li>
          </transition-group>
          <h2 v-if="myRelics.length > 0 && mycards.length > 0" :key="5" :data-index="6">Relics</h2>
          <ul v-if="myRelics.length > 0 && mycards.length > 0" :key="6" :data-index="7">
            <li v-for="(relic, indexSearch) in myRelics" :key="indexSearch" :id="`relic-${indexSearch}`">
              <relic
                :character="relic.character"
                :title="relic.name"
                :rarity="relic.rarity"
                :description="relic.description"
                :browser="false"
                :mydeckIndex="indexSearch"

                @removeRelic="removeRelic"
              ></relic>
            </li>
          </ul>
        </transition-group>
      </div>
      <!-- <transition name="slideLoadout"> -->
      <comparator class="slider" :class="{'slider-active': showCompare}"
        @close="showCompare = !showCompare"
        @removeComparisonItem="removeComparisonItem"
        @upgradeComparisonCard="upgradeComparisonCard"
        @downgradeComparisonCard="downgradeComparisonCard"
        @resetCompare="resetCompare"
        @addCardInfo="addCardToLoadout"
        @addRelicInfo="addRelicToLoadout"
        :compare="comparecards"
        :mycards="mycards"
        :myRelics="myRelics"
      ></comparator>
      <browse class="slider" :class="{'slider-active': showBrowse}"
        @addCardInfo="addCardToLoadout"
        @addRelicInfo="addRelicToLoadout"
        :character="character"
      >
      </browse>
      <!-- </transition> -->
      <transition
        v-bind:css="false"
        @before-enter="beforeEnterButton"
        @enter="enterButton"
        @leave="leave"
        name="buttonanimation"
      >
      <div class="button-dock" v-if="mycards.length > 0" :key="1">
        <!-- :class="{'button-dock-reverse': showCompare}" -->
        <button v-if="!showBrowse" class="button" :class="{'button-red': showCompare}" @click="showCompare = !showCompare; interactionTrue()">
          <p v-if="!showCompare">Compare</p>
          <p v-if="showCompare">Back To Deck</p>
        </button>
        <button class="button" :class="{'button-red': showBrowse }" @click="showBrowse = !showBrowse; interactionTrue()">
          <p v-if="!showBrowse && !showCompare">Browse</p>
          <p v-else-if="!showBrowse && showCompare">Add Items</p>
          <p v-else-if="showBrowse && showCompare">Back</p> <!--style="fontSize: 20px; padding-top: 4px;"" -->
          <p v-else>Back To Deck</p>
        </button>
      </div>
      </transition>
      <prom v-if="false"></prom>
    </div>

  </div>
</template>


<script>

import navbar from './nav';
import card from './cardv2';
import relic from './relic';
import allData from './carddata.json';
import browse from './browse';
import comparator from './comparator';
import characterSelect from './newgame';
import prom from './prom';

function isLocalHost() {
    return window.location.hostname === 'localhost';
}

// vue.axios.defaults.baseURL = isLocalHost() ? 'http://localhost/pg/api/index.php' : '/api/index.php';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// import Velocity from 'velocity-animate';

export default {
  name: 'cards',
  components: {
    card,
    relic,
    navbar,
    allData,
    browse,
    characterSelect,
    comparator,
    prom,
  },

  data() {
    return {
      cards: allData.cards,
      relics: allData.relics,
      character: '',
      ironcladStarterDeck: [
        { type: 'relic', title: 'Burning Blood' },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Bash', plus: false },
      ],
      silentStarterDeck: [
        { type: 'relic', title: 'Ring of the Snake' },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Survivor', plus: false },
        { type: 'card', title: 'Neutralize', plus: false },
      ],
      defectStarterDeck: [
        { type: 'relic', title: 'Cracked Core' },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Zap', plus: false },
        { type: 'card', title: 'Dualcast', plus: false },
      ],
      watcherStarterDeck: [
        { type: 'relic', title: 'Pure Water' },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Defend', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Strike', plus: false },
        { type: 'card', title: 'Eruption', plus: false },
        { type: 'card', title: 'Vigilance', plus: false },
      ],
      mycards: [],
      comparecards: [],

      myRelics: [],
      // tool visibility
      showBrowse: false,
      showCompare: false,

      // local storage
      allowStorageUpdates: false,

      // Animation fix for index bug
      indexGeneratorNumber: 0,
      tempLoad: true,
      // addCardPlusEmit: false, // this is the plus var from emit
      // temp data things
      feedbackMain: 'Something not right?',
      feedbackSmall: 'Let us know!',
    };
  },
  methods: {
    closeCompare() {
      this.closeCompare = !this.closeCompare;
    },
    confirmCharacter(characterName) {
      // console.log(characterName);
      this.character = characterName;
      this.buildDeck('starter');
      this.tempLoad = false;
    },
    buildDeck(type) {
      let theDeck;
      if (type === 'starter') {
        this.comparecards = [];
        if (this.character === 'ironclad') {
          theDeck = this.ironcladStarterDeck;
        } else if (this.character === 'silent') {
          theDeck = this.silentStarterDeck;
        } else if (this.character === 'defect') {
          theDeck = this.defectStarterDeck;
        } else {
          theDeck = this.watcherStarterDeck;
        }
      }
      if (type === 'resume') {
        const cardsStorage = this.localStorageLoad('mycards');
        const relicsStorage = this.localStorageLoad('myRelics');
        theDeck = this.localStorageToBuildDeckArray(
          relicsStorage,
          cardsStorage,
        );
      }

      this.mycards = [];
      this.myRelics = [];

      for (let c = 0; c < theDeck.length; c++) {
        setTimeout(() => {
          // setTimeout(() => {
          if (theDeck[c].type === 'card') {
            this.addCardToLoadout(
              theDeck[c].title,
              theDeck[c].plus,
              this.mycards,
            );
          } else {
            this.addRelicToLoadout( theDeck[c].title, this.myRelics );
          }
          if (c === theDeck.length - 1) {
            // this prevent half build saved decks if a users a leaves while its loading
            this.allowStorageUpdates = true;
            this.localStorageSave('character');
            this.localStorageSave('mycards');
            this.localStorageSave('myRelics');
          }
          // }, 150 * c);
        }, 150);
      }
    },
    addCardToLoadout( name, plusBool, location = false ) {
      const cardID = this.findCardNumberByKey( this.cards, 'name', name );

      // Arrays are ref not copies! Requires a deep copy
      const cardRef = this.cards[cardID];
      const newCard = JSON.parse( JSON.stringify(cardRef) );
      // fix card character issues
      if (name === 'Defend' || name === 'Strike') {
        newCard.character = this.character;
      }
      const plusSymbol = plusBool ? '+' : '';

      newCard.plus = plusBool;
      // for animation fixes
      newCard.keyNumberIndex = this.keyIndexGenerator()
      // console.groupEnd();
      if (location !== false) {
        console.log(`-- ${name}${plusSymbol} added --`);

        // new method to push things to correct arrays
        location.push(newCard);
        if (location === 'comparecards') {
          this.cardCompared(name)
        } else {
          this.cardAddedToDeck(name) // db stats
        }
        return;
      }
      // if not used resort to old method
      if (this.showCompare && this.showBrowse) {
        console.log(`-- ${name}${plusSymbol} added to comparator --`);
        this.comparecards.push(newCard);
        this.$ga.event({
          eventCategory: 'Slay The Spire',
          eventAction: 'Compare Card',
          eventLabel: name,
          eventValue: 1,
        });
        // Capture card comparison statistic
        this.cardCompared(name)
      } else {
        console.log(`-- ${name}${plusSymbol} added to loadout --`);
        this.cardAddedToDeck(name); // db stats
        this.mycards.push(newCard);
      }
      // focus search after adding card
      document.getElementById('browseSearchInputcards').focus({ preventScroll: true });
    },
    addRelicToLoadout(name, location = false) {
      const relicID = this.findCardNumberByKey(this.relics, 'name', name);
      // console.log(relicID);

      // Arrays are ref not copies! Requires a deep copy
      const relicRef = this.relics[relicID];
      const newRelic = JSON.parse(JSON.stringify(relicRef));

      console.log(`-- ${name} added to loadout --`);
      if (location !== false) {
        // new method to push things to correct arrays
        location.push(newRelic);
        return;
      }
      // if not used resort to old method
      if (this.showCompare && this.showBrowse) {
        this.comparecards.push(newRelic);
        this.$ga.event({
          eventCategory: 'Slay The Spire',
          eventAction: 'Compare Relic',
          eventLabel: name,
          eventValue: 1,
        });
      } else {
        this.myRelics.push(newRelic);
      }
      // focus search after adding card
      document.getElementById('browseSearchInputrelics').focus({ preventScroll: true });
    },
    findCardNumberByKey(array, key, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return i;
        }
      }
      return null;
    },
    removeCard(cardID) {
      this.mycards.splice(cardID, 1);
    },
    removeRelic(cardID) {
      this.myRelics.splice(cardID, 1);
    },
    upgradeCard(cardID) {
      this.mycards[cardID].plus = true;
      this.localStorageSave('mycards');
    },
    downgradeCard(cardID) {
      this.mycards[cardID].plus = false;
      this.localStorageSave('mycards');
    },
    removeComparisonItem(itemID) {
      this.comparecards.splice(itemID, 1);
    },
    upgradeComparisonCard(cardID) {
      this.comparecards[cardID].plus = true;
      this.localStorageSave('comparecards');
    },
    downgradeComparisonCard(cardID) {
      this.comparecards[cardID].plus = false;
      this.localStorageSave('comparecards');
    },
    resetCompare(cardID) {
      this.comparecards = [];
    },
    localStorageSave(name) {
      if (this.allowStorageUpdates) {
        // prevent breaking a half loaded deck (if a user leaves while its loading)
        localStorage.removeItem(name);
        localStorage.setItem(name, JSON.stringify(this[name]));
      }
    },
    localStorageLoad(name) {
      //console.log(JSON.parse(localStorage.getItem(name)));
      return JSON.parse(localStorage.getItem(name));
    },
    localStorageToBuildDeckArray(relics, cards) {
      //console.log('building deck');
      const deckArray = [];
      for (let r = 0; r < relics.length; r++) {
        deckArray.push({
          type: 'relic',
          title: relics[r].name,
        });
      }
      for (let c = 0; c < cards.length; c++) {
        deckArray.push({
          type: 'card',
          title: cards[c].name,
          plus: cards[c].plus,
        });
      }
      return deckArray;
    },
    feedbackTemp() {
      this.feedbackMain = 'Coming Soon';
      this.feedbackSmall = 'Contact us from the homepage.';
      setTimeout(() => {
        this.feedbackMain = 'Something not right?';
        this.feedbackSmall = 'Let us know!';
      }, 1500);
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(40px)';
      // el.style.transform = 'scale(1.0)';
      // Velocity(
      //     el,
      //     { opacity: 0, translateY: '30px'},
      //   )
      // el.style.width = 'inherit';
    },

    enter(el, done) {
      var delay = el.dataset.index * 80;
      setTimeout(() => {
        Velocity(
          el,
          { opacity: 1, translateY: '0px'},
          { complete: done }
        )
      }, delay)
    },
    beforeEnterCard(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(800px)';
    },
    enterCard(el, done) {
      // console.log(initialDelay);
      const initialDelay = parseInt(el.dataset.index - 3) * 80;
      var delay = (parseInt(el.dataset.itemindex) * 80) + initialDelay;

      // console.log(delay);

        setTimeout(() => {
          Velocity(
            el,
            { opacity: 1, translateY: '0px'},
            { complete: done }
          )
        }, delay)

    },
    beforeEnterButton(el) {
      el.style.opacity = 0;
    },
    enterButton(el, done) {
      // console.log(el);
      setTimeout(() => {
        Velocity(
          el,
          { opacity: 1},
          { complete: done }
        )}, 800)
    },
    leave(el, done) {
      // // var delay = el.dataset.index * 150
      // const elPosition = el.getBoundingClientRect()
      // console.log(elPosition.left);
      // console.log(elPosition.top);

      el.style.position = "absolute";

        Velocity(
          el,
          { opacity: 0, translateY: '-30px'}, 
          { duration: 190},
          { complete: done }
        )
        // el.style.zIndex = "-1";
        // console.log(this.getPosEl(el));
        // console.log(el.getBoundingClientRect());

        el.style.zIndex = -1;
    },
    afterEnter(el) {
      this.tidyStyles(el);
      // el.removeAttribute('style');
    },
    afterLeave(el) {
      this.tidyStyles(el);
      // el.removeAttribute('style');
      this.removeChild(el);
    },
    tidyStyles(el) {
      // console.log(el);
      // console.log('working');
      this.$nextTick(function () {
        el.removeAttribute("style")
      })
    },
    keyIndexGenerator() {
      this.indexGeneratorNumber += 1;
      return this.indexGeneratorNumber;
    },
    getPosEl(obj) {
      var curleft = 0;
      var curtop = 0;
      if (obj.offsetParent) {
        do {
			  curleft += obj.offsetLeft;
        curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
      return [curleft,curtop];
      }
    },
    interactionTrue() {
      if (!localStorage.getItem('interaction')){
        console.log('-- user interaction --');
      }

      localStorage.setItem('interaction', 'true')
    },

    // Server Calls
    async cardCompared (name) {
      try {
        const res = await this.$api.post(
          '/', {
            query: `
              mutation ($name: String!){
                cardCompared(name: $name) {
                  success
                }
              }
            `,
            variables: {
              name: name
            }
        })
        console.log(res.data.data);

      } catch (e) {
        console.log('err', e)
      }
    },
    async cardAddedToDeck (name) {
      try {
        const res = await this.$api.post(
          '/', {
            query: `
              mutation ($name: String!){
                cardAddedToDeck(name: $name) {
                  success
                }
              }
            `,
            variables: {
              name: name
            }
        })
        console.log(res.data.data);

      } catch (e) {
        console.log('err', e)
      }
    },
  },
  watch: {
    // Browser Data Storing
    character() {
      this.localStorageSave('character');
    },
    mycards() {
      this.localStorageSave('mycards');
      this.interactionTrue
    },
    myRelics() {
      this.localStorageSave('myRelics');
      this.interactionTrue()
    },
    comparecards() {
      this.localStorageSave('comparecards');
      this.interactionTrue()
    },
    // Google Analytics
    showBrowse() {
      // GA pageviews for browse
      // Lock bbackground content
      if (this.showCompare) {
        if (this.showBrowse) {
          this.$ga.page(this.$route.path + '/compare/browse');
        } else {
          this.$ga.page(this.$route.path + '/compare');
        }
      } else {
        if (this.showBrowse) {
          this.$ga.page(this.$route.path + '/browse');
          document.body.classList.add("scroll-lock");
        } else {
          this.$ga.page(this.$route.path);
          document.body.classList.remove("scroll-lock");
        }
      }
    },
    showCompare() {
      // GA pageviews for compare
      if (this.showCompare) {
        this.$ga.page(this.$route.path + '/compare');
        document.body.classList.add("scroll-lock");
      } else {
        this.$ga.page(this.$route.path);
        document.body.classList.remove("scroll-lock");
      }
    },
  },
  created() {
    let Velocity = document.createElement('script');
    Velocity.setAttribute('src', '//cdnjs.cloudflare.com/ajax/libs/velocity/1.2.3/velocity.min.js');
    // Velocity.setAttribute('src', './../../../../static/js/velocity.js');
    document.head.appendChild(Velocity);
  },
  mounted() {
    // Loading saved deck
    if (this.$route.path === '/tools/slaythespire/loadout') {
      this.character = this.localStorageLoad('character');

      setTimeout(() => {
        this.tempLoad = false;
        console.log('--- Loading Saved Deck ---');

        // console.log(this.localStorageLoad('character'));

        if (localStorage.getItem('mycards')) {
          this.buildDeck('resume');
        }
        if (localStorage.getItem('comparecards')) {
          this.comparecards = JSON.parse(localStorage.getItem('comparecards')); // convert back
        }
      }, 1500);

    }
      // if (this.$route.path.indexOf('newgame')) {
      //   this.mycards = [];
      // }

  },
  beforeDestroy() {
    // Final save attempt, although it should be saved before this
    this.localStorageSave('character');
    this.localStorageSave('mycards');
    this.localStorageSave('myRelics');
    this.localStorageSave('comparecards');
    document.body.classList.remove("scroll-lock");
  }
};
</script>

<style lang="scss" scoped>
.char-select {
  background: rgba(#10212b, 1);
}

.loadout {
  background: rgba(#10212b, 0.75);
  padding: 0 0 170px;
}

// .main-bg {
//   position: absolute;
//   top: 0;
//   z-index: 0;
//   height: 100vh;
//   width: 100%;
//   // background:
//   //   /* top, transparent red, faked with gradient */
//   //   linear-gradient(
//   //     to bottom,
//   //     rgba(#08131a, 0.75),
//   //     rgba(#10212b, 1)
//   //   );
//   // background-image:
//   //   /* top, transparent red, faked with gradient */
//   //   linear-gradient(
//   //     rgba(#08131a, 0.75),
//   //     rgba(#10212b, 1),
//   //     red
//   //   ),
//   //   /* bottom, image */
//   //   url('/../assets/backgrounds/ironcladPortrait.jpg');
//     /* bottom, image */;
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position-x: right;
//     // filter: blur(4px);
//     .img {
//       position: relative;
//       z-index: -100;
//       opacity: 0.5;
//     }
// }

.nav-alt {
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  // position: relative;
  // z-index: 10;
}

.temp-loading {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.return {
  color: #fff6e1;
  text-decoration: none;
  font-size: 20px;
  padding: 22px 22px;
  display: flex;
  align-items: center;
  &:hover {
    > .text .mini {
      opacity: 0.7;
    }
  }
  > img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 16px;
  }
  > .text {
    margin-top: -16px; 
    p {
      padding: 0;
      margin: 0;
    }
    .mini {
      opacity: 0;
      font-size: 12px;
      transition: all 220ms;
      margin: 0 0 4px 0;
      text-align: center
    }
  }
}

.issue {
  color: #fff6e1;
  text-decoration: none;
  padding: 10px 22px;
  &:hover {
    cursor: pointer;
  }
  > p {
    margin: 4px 0;
    text-align: center;
  }
  > .main {
    font-size: 16px;
  }
  > .mini {
    font-size: 12px;
    opacity: 0.75;
  }
}

.cards {
  max-width: 1280px;
  margin: 0 auto;
}
ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 40px;
  margin: 0 auto;
  // position: relative;
}
li {
  width: 17.6%;
  // width: 45%;
  margin: 1.2% 1.2%;
  display: flex;
  align-items: flex-end;
  // overflow: hidden;
  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  // transition: scale 0.5s easing's easeOutCubic curve;
  &:hover {
    transform: scale(1.28, 1.28);
    transition: 0s;
    z-index: 100;
    & ~ #sts-card-base-img {
      box-shadow: 0 0 20px 20px rgba(#000, 0.4);
    }
    // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
  }
}

h1 {
  text-align: center;
  font-size: 36px;
  margin: 20px 0;
  text-transform: capitalize;
}

h2 {
  margin: 20px 54px;
  text-align: left;
  font-size: 32px;
  text-shadow: 3px 3px 0 #000;
  // text-align: center;
}

h3 {
  margin: 0 60px;
  // text-align: center;
  font-size: 22px;
  text-shadow: 3px 3px 0 #000;
  font-weight: 700;
  > span {
    opacity: 0.7;
    > span {
      margin: 0 3px;
    }
  }
}

.h2underline {
  width: 80px;
  text-align: left;
  margin-left: 80px;
}

.slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;

  transform: translateY(calc(100vh + 100px));
  // transition: all 0.3ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
  transition: all 0.195s linear;
  &-active {
    transform: translateY(0);
    overflow-y: scroll;
    transition: all 0.225s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.button {
  width: 220px;
  height: 100px;
  background: none;
  outline: none;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  transform: scale(0.9);
  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background-image: url('./../assets/ui/buttonBlueGrey.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -10px;
  &:hover {
    transform: scale(1);
    transition: 0s;
    background-image: url('./../assets/ui/buttonBlueGold.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &-red {
    background-image: url('./../assets/ui/buttonRedGrey.png');
    &:hover {
      background-image: url('./../assets/ui/buttonRedGold.png');
    }
  }

  > p {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
  }

  &-dock {
    position: fixed;
    bottom: 30px;
    right: 32px;
    z-index: 4000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: 0;
    // &-reverse {
    //   flex-direction: row-reverse;
    // }
  }
}

.function-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
}

.horizontal-rule {
  max-width: 92%;
  height: 4px;
  border-radius: 2px;
  // background: rgba(#10212B, 0.85);
  background: linear-gradient(left, #c0b68a, #8f8a6c);
  box-shadow: rgba(#686249, 1) 0 3px 0 0;
  margin: 28px auto 50px;
}

// .scroll-lock {
//   overflow: hidden !important;
//   // max-height: 100vh;
// }

// Animations
.slideLoadout-leave-active,
.slideLoadout-enter-active {
  transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slideLoadout-enter {
  transform: translateY(100%);
  // z-index: 3000;
}
.slideLoadout-leave-to {
  transform: translateY(0);
  // z-index: 2900;
}

// .slidein-leave-active,
// .slidein-enter-active {
//   transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
//   transition: translateY(0)
// }
// .slidein-enter {
//   transform: translateY(100%);
//   // z-index: 3000;
// }
// .slidein-leave-to {
//   transform: translateY(100%);
//   // z-index: 2900;
// }

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-enter-active {
  transition: all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.slide-leave-active {
  transition: all 0ms linear;
}
.slide-enter {
  transform: translateY(1000px);
}
.slide-leave-to {
  transform: scale(0.5, 0.5) translateY(-1000px);
}
.slide-move {
  transition: transform 1s;
}
</style>

<style lang="scss">
.loadout {
  min-height: 100vh;
  // background: #03053b;
  color: #fff6e1;
  font-family: 'Kreon', serif;
  background: #10212b;
  // background-image: url('./../assets/backgrounds/slshero.jpg');
  // background-size: cover;
  // background-position: center;
  // background-attachment: fixed;
  padding: 0 0 0 0;
}
</style>
