<template>
<div>
  <p class="wrap" v-if="countdownToDisplay > 0 && countdownToDisplay < 15">Showing advert in {{countdownToDisplay}} seconds..</p>
  <transition name="slide-fade">
    <div class="wrap" v-if="countdownToDisplay === 0">
      <p v-if="countdownToDisplay > 0 && countdownToDisplay < 31">Showing advert in {{countdownToDisplay}} seconds..</p>
      <div style="width: 300px; height: 250px; background: #444">
        <img src="" alt="" >
      </div>
      <button @click="displayTime = 0" class="dismiss">Dismiss Advert</button>
    </div>
  </transition>
</div>
  
</template>

<script>
export default {
  name: 'prom',
  data (){
    return {
      countdownToDisplay: null,
      displayTime: null,
      timeBetween: 5, // const (edit this)
      timeShown: 5, // const (edit this)
    }
  },
  mounted() {
    this.$options.interval = setInterval(() => {
      if (this.countdownToDisplay > 0) {
        if (localStorage.getItem('interaction') === 'true') {
          this.countdownToDisplay -= 1;
          // console.log(this.countdownToDisplay);
        }
      } else if (this.displayTime > 0){
        this.displayTime -= 1;
        // console.log(this.displayTime);
      } else {
        // prevent stopping initial countdown
        if (this.countdownToDisplay !== null) {
          localStorage.removeItem('interaction')
        }
        this.countdownToDisplay = this.timeBetween;
        this.displayTime = this.timeShown;
      }
    }, 1000);
  },
  beforeDestroy: function () {
    clearInterval(this.$options.interval);
  }
}
</script>

<style lang="scss" scoped>
  .wrap {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5000;
    margin: 0 16px 16px;
    display: flex;
    flex-direction: column;
  }
.dismiss {
  margin-top: 16px;
}

.slide-fade-enter-active {
  transition: all 1.1s ease-out; //cubic-bezier(1.0, 0.5, 0.8, 1.0)
}
.slide-fade-leave-active {
  transition: all .6s linear;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
  opacity: 0;
}
</style>

